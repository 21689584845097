<!-- 选择议价商品 -->
<template>
<div :class="themeClass">
  <el-dialog  title="议价商品" v-dialogDrag  v-if="showModel" width="50%"  :visible.sync="showModel" :header-cell-style="{'text-align': 'center',fontWeight: '400',color: '#333',}" :before-close="handleClose" :close-on-click-modal="false" :close-on-press-escape="false">
	<div style="display: flex;align-items: center;justify-content: center;margin-top: 20px;">
		<el-input   prefix-icon="el-icon-search" placeholder="请输入商品模糊查询" v-model.trim="searchText" @change="getTableList(1)"></el-input>
		<el-button class="dia-btn text-center" style="background-color: #0173FE; display: inline-block;width: 77px;border-radius: 0px;" type="primary" @click="getTableList(1)">搜索</el-button>
	</div>
    <el-table v-loading="tableLoading" :data="tableData" height="350" @selection-change="changeCheckBoxRow" style="width: 100%;" border   :header-cell-style="{'text-align': 'center',fontWeight: '400',color: '#000000',}">
      <el-table-column type="selection" align="center" width="30" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="商品" align="left">
          <template slot-scope="scope">{{ scope.row.fGoodsFullName }}</template>
      </el-table-column>
      <el-table-column label="类型" align="center" width="190">
          <!-- <template slot-scope="scope">{{ scope.row.fGoodsPrice }}</template> -->
          <template slot-scope="scope">{{ scope.row.fPackingType }}</template>
      </el-table-column>
      <el-table-column label="当前价" align="center" width="160">
          <template slot-scope="scope">¥{{ scope.row.fGoodsPrice|NumFormat }}</template>
      </el-table-column>
    </el-table>
	<div style="display: block;">
		<el-pagination
		      @current-change="handleCurrentChange"
			  @size-change="handleSizeChange"
		      :current-page="pagesStart"
			  :page-sizes="[10, 20, 30, 50]"
		      :page-size="pagesize"
		      layout="total, sizes, prev, pager, next, jumper"
		      :total="total"
		      @prev-click="prevclick"
		      @next-click="nextclick"
			  >
		</el-pagination>
	</div>

    <div slot="footer" class="dialog-footer">
      <el-button class="dia-btn text-center" @click="handleClose">取消</el-button>
      <el-button class="dia-btn text-center confirm-btn" @click="submitForm">确定</el-button>
    </div>
  </el-dialog>
</div>

</template>

<script>
import {
	mapGetters
} from "vuex";
export default {
  props: {
    // v-model
    showModel: {
      type: Boolean,
      default: false,
    },
    shopID:{
        type:String,
        default:''
    },
  },
	computed: {
		...mapGetters(["getThemeName"]),
		themeClass() {
			return `theme-${this.getThemeName}`;
		},
	},
  watch:{
    showModel(val){
      if(val==true){
        this.getTableList()
      }
    }
  },
  data() {
    return {
      tableLoading: true, // 表格 加载等待 变量
      searchText: "", //搜索框输入内容
      tableData: [],
	  // page:1,
	  // total: 0, //条数
	  // page: 1, //页数
	  // maxResultCount: 1
      checkData: [], //复选框选中的数据
	  pagesize:10,//每页数量
	  pagenowsize:0,//当前的页数数字
	  total:0,//总条目数量
	  pagesStart:1,//页面当前页数
    };
  },
  mounted() {
    // this.getTableList()
  },
  methods: {
    submitForm() {
        if (this.checkData.length ==0) {
          this.$message({
            type:'warning',
            message:"请选择议价商品"
          });
          return;
        }
      // console.log('this.checkData',this.checkData);
      // 复选框选择数据 this.checkData
      this.$emit("chooseData", this.checkData);
      // 关闭弹窗
      this.handleClose();
    },
	prevclick(){
		this.pagesStart= this.pagesStart-1;
		this.getTableList();
	},
	nextclick(){	
		this.pagesStart=this.pagesStart+1;
		this.getTableList();
	},
	handleCurrentChange(e){
		this.pagesStart = e
		this.getTableList();
	},
	handleSizeChange(e){
		this.pagesStart = 1;
		this.pagesize = e;
		this.getTableList();
		console.log(this.pagesStart,"当前页数")
		console.log("当前数量",e)
	},
    // 弹窗关闭事件
    handleClose() {
      this.$emit("change", !this.showModel);
    },
    // 表格数据
    getTableList(e) {
	  if(e && e == 1){
		  this.pagesize = 10;//每页数量
		  this.pagenowsize = 0;//当前的页数数字
		  this.total = 0;//总条目数量
		  this.pagesStart = 1;//页面当前页数
	  }	
      this.tableLoading = true;
      console.log("sdfgdfgh");
      this.ApiRequestPost(
	    "api/mall/ebsale/goods-price/get-list-by-shopid-not-concrete", {
	    	fShopUnitID: this.shopID,
	    	fAppTypeID: this.fAppTypeID,
	    	filter: this.searchText,
	    	maxResultCount: this.pagesize,
	    	skipCount: (this.pagesStart-1)*this.pagesize,
	    }
	).then(
        (result) => {
          console.log(result, "商品列表-------------------");
          this.tableLoading = false;
          this.tableData = result.obj.items;
          this.tableLoading=false
		  this.total = result.obj.totalCount;
        },
        (rej) => {
          this.tableLoading = false;
        }
      );
    },
    //表格斑马线
    // tableRowClassName(rowIndex) {
    //   if (rowIndex.rowIndex % 2 === 0) {
    //     return "warning-row";
    //   } else if (rowIndex.rowIndex % 2 === 1) {
    //     return "success-row";
    //   }
    // },
    // 行复选框选中事件
    changeCheckBoxRow(selection) {
      this.checkData = selection;
    },
    // 复选框全部选中事件
    // changeCheckBoxAllRow(selection) {
    //   this.checkData = selection;
    // },
  },
};
</script>

<style lang="scss" scoped>
.el-table_2_column_8{
  padding-left: 10px;
}
.el-table-column--selection{
  ::v-deep .cell{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
::v-deep td:first-child{
  padding-left: 0 !important;
}
::v-deep .gutter {
	display: none;
}
::v-deep .el-table--border{
  padding-left: 0 !important;
}
::v-deep .el-table--border td:first-child .cell{
  padding-left: 0 !important;
}
::v-deep .el-table-column--selection .cell {
    padding-left: 0;
    padding-right: 0;
}
::v-deep .el-table .cell.el-tooltip {
    min-width: 30px;
}
::v-deep td:first-child{
  padding-left: 0 !important;
}
::v-deep .el-table--border th:first-child .cell{
  padding-left: 0;
  padding-right: 0;
}
::v-deep .el-table th>.cell{
  padding-left: 0;
  padding-right: 0;
}
::v-deep .el-input__inner{
  padding-left: 30px !important;
  border-radius: 0px !important;
}
::v-deep .el-dialog{
    width: 540px;
}
::v-deep .el-dialog__body{
  padding: 0 20px;
}
::v-deep .el-table{
  width: 490px;
  max-height: 350px;
  overflow-y: auto;
  margin: 13px 0;
}
::v-deep .el-dialog__headerbtn{
		font-size: 20px !important;
}
.w490{
  width: 490px;
}
.dia-btn{
  width: 60px;
  line-height: 30px;
  height: 30px;
}
.el-button{
  padding: 0;
  font-size: 12px;
}
.el-table::before {
  z-index: inherit;
}
.confirm-btn {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
}
</style>
