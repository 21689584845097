<template>
	<div class="width-fill">
		<div class="priceBuy-bgcimg" :class="themeClass">
			<div class="breadcrumbDiv font-color-999">
				<div class="flex-row font-color-999">
					<span class="margin-top-3px">您的位置：</span>
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: '/businessBuy' }">企业购</el-breadcrumb-item>
						<el-breadcrumb-item>
							<div class="color-theme">申请议价</div>
						</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
			</div>
			<div class="content">
				<div class="bg-fff padding-10">
					<div class="flex-row-space-between-center">
						<div class="font-weight700 margin-tb-3-16">店铺</div>
						<div class="margin-t3-r9 search-shop">
							<el-input placeholder="请输入店铺模糊查询" prefix-icon="el-icon-search" v-model.trim="shopFilterText"
								@change="shopFilterChange()">
							</el-input>
						</div>
					</div>

					<div class="MyFocus-content" v-loading="loading">
						<div class="text-center font-color-ae flex-row-center-center margin-t-30"
							v-if="listData.length == 0">
							<svg-icon icon-class="NoContent" />
							<!-- 暂无数据 -->
						</div>
						<div class="popup_content" v-for="(item, index) of listData" :key="index" :data-index="index"
							@click="shopitemClick(item.fShopUnitID)">
							<!-- <div class="popup_content" v-for="(item, index) of listData" :key="index" :data-index="index" @click="editform == false ? shopitemClick(item.fShopUnitID) : ''"> -->
							<div class="popup_content_item pointer flex-row"
								:class="item.checked == true ? 'borederColor' : ''" style="align-items: center;">
								<div class="shop_pic">
									<div class="MyFocus-img">
										<img :src="item.fShopLogoPath" alt="" />
									</div>
								</div>
								<div class="item_right">
									<div class="item_shop">
										<el-tooltip :content="item.fShopName" placement="top">
											<div class="shop_name margin-b-10 font-size13 oneline-show-hide">
												{{ item.fShopName }}
											</div>
										</el-tooltip>
										<el-tooltip v-if="item.fRemark" :content="item.fRemark" placement="top">
											<div class="shop_name font-size12 oneline-show-hide" style="color: #A1A1A1;width: 104px;height: 24px;white-space: nowrap;display: block;">
												{{ item.fRemark }}
											</div>
										</el-tooltip>
									</div>
									<p v-if="item.fShopTypeName=='自营店'"
										class="tag-box text-center font-color-fff font-size12">
										{{ item.fShopTypeName }}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="bg-fff padding-lr-10 margin-tb-10 padding-b-10">
					<div class="flex-row-space-between-center h50">
						<div class="font-weight700 font-size16">商品</div>
						<!-- <div class="margin-t3-r9">
							<el-input placeholder="输入商品名称" prefix-icon="el-icon-search" v-model="goodsFilterText" @change="goodsFilterChange()">
							</el-input>
						</div> -->
						<div class="addBargainingGoods text-center" @click="dialogVisible=true">添加议价商品</div>
					</div>
					<div>
						<el-table ref="tableData" :data="tableData" style="width: 100%" border highlight-current-row
							:header-cell-style="{'text-align': 'center',fontWeight: '400',color: '#000000',}"
							v-loading="tableLoading" height="261px" :show-overflow-tooltip="true">
							<div class="flex-row-center-center position-relative" v-if="tableData.length==0"
								slot="empty">
								<div class="flex-row-center-center">
									<svg-icon icon-class="NoActivity" />
								</div>
								<div class="addtext text-center font-color-000 position-absolute" style="bottom:0">
									请添加议价商品</div>
							</div>
							<!-- <el-table-column fixed type="selection" width="40" align="center">
							</el-table-column> -->
							<el-table-column prop="shopName" label="商品信息" width="300" style="text-align: center;">
								<template slot-scope="scope" style="text-align: center;">
									{{ scope.row.fGoodsFullName }}
								</template>
							</el-table-column>
							<el-table-column prop="fGoodsPrice" label="当前价" width="222" align="center">
								<template slot-scope="scope">
									{{ scope.row.fGoodsPrice }}元/{{scope.row.fUnitName}}
								</template>
							</el-table-column>
							<!-- <el-table-column prop="fDeliveryID" label="提货方式" width="156" align="center">
								<template slot-scope="scope">
									<div class="deliveryBox font-size12">
										<el-select v-model="scope.row.fDeliveryID" placeholder="请选择提货方式" @change="fDeliveryChange(scope.row)">
											<el-option v-for="item in deliveryMethodoptions" :key="item.value" :label="item.label" :value="item.value"
											 style="font-size: 12px">
											</el-option>
										</el-select>
									</div>
								</template>
							</el-table-column> -->
							<el-table-column prop="fGoodsPrice" label="申请单价" width="236" align="center">
								<template slot-scope="scope">
									<div class="flex-row deliveryBox"
										style="justify-content: center;align-items: center;">
										￥
										<el-input placeholder="请输入价格" maxlength='11'
											@input="setOnlyMoney(scope.row.fApplyGoodsPrice,scope.$index)"
											style="margin-left: 0;" v-model="scope.row.fApplyGoodsPrice">
											<!-- :disabled="scope.row.disabled == false" -->
										</el-input>
									</div>
								</template>
							</el-table-column>
							<!-- 	<el-table-column prop="fSalesVolume" label="数量" width="156" align="center">
								<template slot-scope="scope">
									<div>
										<el-input-number v-model="scope.row.fSalesVolume" :min="1" :max="9999" label=""></el-input-number>
									</div>
								</template>
							</el-table-column> -->
							<el-table-column prop="fDelivery" label="提货方式" width="200" align="center">
								<template slot-scope="scope">
									<el-select v-model="subscript[scope.$index]" placeholder="请选择" @change='cjchange'>
									    <el-option
											v-for="item in tableData[scope.$index].delivery"
											:label="item.fDelivery"
											:value="item.fDeliveryID">
									    </el-option>
									  </el-select>
								</template>
							</el-table-column>
							<el-table-column prop="operation" label="操作" width="120" align="center">
								<template slot-scope="scope">
									<div class="deleteBtn text-center color-theme border-color-theme margin-auto"
										@click="deleteGood(scope.row.fGoodsID)">删除</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="bg-fff margin-t-10 padding-t-21 application-form">
					<el-form :model="form" :rules="rules" ref="form" v-loading="formloading" label-width="150px"
						class="demo-ruleForm">
						<div class="text-center font-size16 margin-b--5">
							<span class="iconfont icon-biaodan xinzengIcon color-theme"></span>
							<span> {{ pageTitle }}&nbsp;&nbsp;</span>
						</div>
						<div class="form-content">
							<el-row>
								<el-col :span="12">
									<el-form-item label="询价单号" prop="fBillNumber">
										<el-input disabled v-model="form.fBillNumber" placeholder="请输入询价单号"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item prop="fCapTypeIDList">
										<div slot="label" class="flex-row">
											<div style="margin-left: auto" class="flex-row">
												<div class="color-red margin-r-5">*</div>
												付款方式(可多选)
											</div>
										</div>
										<el-select multiple placeholder="货币资金" v-model="fCapTypeIDList"
											:clearable="false" style="width: 100%" @change="changefCapTypeIDList">
											<el-option v-for="(item, index) in fCapTypeOptions" :key="index"
												:disabled="item.checked" :label="item.fCapType"
												:value="item.fCapTypeID">
											</el-option>
										</el-select>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="价格开始时间" prop="fBeginTime">
										<!-- :picker-options="pickerOptions1" 自定义开始时间结束时间，以前做好的，需要可以加上 -->
										<el-date-picker v-model="form.fBeginTime" type="datetime" class="dataspicker"
											 :editable="false" value-format="yyyy-MM-dd HH:mm:ss"
											format="yyyy-MM-dd HH:mm:ss" placeholder="选择价格开始日期">
										</el-date-picker>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="价格结束时间" prop="fEndTime">
										<!-- :picker-options="pickerOptions2" 自定义开始时间结束时间，以前做好的，需要可以加上 -->
										<el-date-picker v-model="form.fEndTime" type="datetime" class="dataspicker"
											 :editable="false" value-format="yyyy-MM-dd HH:mm:ss"
											format="yyyy-MM-dd HH:mm:ss" placeholder="选择价格结束日期">
										</el-date-picker>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="备注">
										<el-input v-model="form.fRemark" placeholder="请输入备注"></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<div class="agreement-box">
								<div v-if="zhuceData.length>0" class="agreement-box">
									<el-checkbox-group v-model="radio">
										<el-checkbox label="我已阅读并同意"></el-checkbox>
									</el-checkbox-group>
									<span v-for="(item,index) in zhuceData" :key>
										<span v-if="index != 0">、</span><span style="cursor: pointer;"
											class="color-theme font-size12 lh21"
											@click="showAgreeDia(item.fAgreementHistID,item.fAgreementTypeID)">《{{item.fTitle}}》</span>
									</span>
								</div>
								
							</div>
						</div>
						<div class="bg-box"></div>
						<div class="footer">
							<el-form-item>
								<div class="background-color-theme" @click="submitForm('form')">提交申请</div>
							</el-form-item>
						</div>
					</el-form>
				</div>
			</div>
		</div>
		<agreementDialog :iContents='iContent' ref="areDialog" @handleClick="handleClick" />
		<addBargainingGoodsDialog :showModel="dialogVisible" @change="changeModel" @chooseData="handleAddDialogChange"
			:shopID="form.fShopUnitID"></addBargainingGoodsDialog>
	</div>
</template>
<script>
	import {
		mapGetters
	} from "vuex";
	import agreementDialog from '@/components/agreementDialog';
	import addBargainingGoodsDialog from '@/views/businessBuy/components/AddBargainingGoodsDialog.vue';
	export default {
		components: {
			agreementDialog,
			addBargainingGoodsDialog
		},
		data() {
			return {
				subscript:[],
				mode:'',
				delivery: [],
				value: '',
				shopID: '', //选中的店铺
				dialogVisible: false, //添加议价商品弹框
				iContenthtml: '<p>尊敬的各位用户：</p><p>我们将积分商城商品通知，过年快递正常发货！</p>',
				multipleSelection: null,
				tableLoading: false,
				pageTitle: "议价申请单",
				zhuceData: [],
				selectdefult: "",
				iContent: '',
				xieyilists:[],
				form: {
					fBillNumber: "",
					fCapTypeIDS: "",
					fCapTypeS: "",
					fBeginTime: "",
					fEndTime: "",
					fUsePlace: "",
					fReason: "",
					fRemark: "",
					fShopUnitID: "",
					fAppTypeID: 1,
					details: [],
				},
				loading: false,
				formloading: false,
				fCapTypeIDList: [],
				radio: false, //协议的按钮
				rules: {
					fCapTypeIDList: [{
						validator: (rule, value, callback) => {
							if (this.fCapTypeIDList.length < 1) {
								callback(new Error("请选择付款方式"));
							} else {
								callback();
							}
						},
					}, ],
					fBeginTime: [{
						required: true,
						message: "请选择价格开始时间",
						trigger: "blur",
					}, ],
					fEndTime: [{
						required: true,
						message: "请选择价格结束时间",
						trigger: "blur",
					}, ],
					fDelivery: [{
						required: true,
						message: "请选择提货方式",
						trigger: "blur",
					}],
				},
				pickerOptions1: {
					disabledDate: (time) => {
						if (this.form.fEndTime) {
							return (
								time.getTime() >
								new Date(this.form.fEndTime).getTime() - 86400000 ||
								time.getTime() < new Date() - 86400000
							);
						} else {
							return time.getTime() < new Date()-86400000;
						}
					},
					// disabledDate(time) {
					//         let dateTime = new Date();
					//         let startDateTime = dateTime.setDate(dateTime.getDate() - 1);
					//         let endDateTime = dateTime.setDate(dateTime.getDate() + 7);
					//         return (
					//           time.getTime() < new Date(startDateTime).getTime() ||
					//           time.getTime() > new Date(endDateTime).getTime()
					//         );
					//       },
					//       selectableRange:
					//         new Date(new Date().setHours(new Date().getHours() + 1)).format(
					//           'hh'		
					//         ) + ':00:00 - 23:59:00'
				},
				pickerOptions2: {
					disabledDate: (time) => {
						if (this.form.fBeginTime) {
							return time.getTime() < new Date(this.form.fBeginTime).getTime();
						} else {
							return time.getTime() < Date.now() - 86400000;
						}
					},
				},
				page: 1, //页数
				maxResultCount: 200,
				shopFilterText: "",
				listData: [], //店铺存储变量
				deliveryMethodoptions: [], //提货方式变量
				goodsFilterText: "",
				tableData: [], // 商品tab存储变量
				fCapTypeOptions: [],
				tableData: [], //表格选中数据
				editId: "",
				editform: false, //临时变量  判断是编辑还是添加
				loading: false,
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		mounted() {
			this.getShopList(); //获取店铺
			this.getdedelivery(); //获取提货方式
			// this.inintApplicationPriceNum();
			this.subscript[0] = '自提'
			// 判断是否从店铺首页跳转过来
			if (this.$route.query.userId) {
				this.shopitemClick(this.$route.query.userId)
				// 变换checked控制样式
				for (let item of this.listData) {
					if (this.$route.query.userId == item.fShopUnitID) {
						item.checked = true;
					} else {
						item.checked = false;
					}
				}
			}
			this.editform = this.$route.query.edit;
			if (this.editform == true) {
				console.log('编辑');
				this.editId = this.$route.query.id;
				// this.getEditList();
			} else {
				this.inintApplicationPriceNum(); //获取议价单号
			}
			this.getAgreement()
			this.Deliverymethod()
		},
		// watch: {
		// 	$route(to, from) {
		// 		console.log('to', to)
		// 		console.log('from', from)
		// 		//监听路由是否变化
		// 		if (to.query.id != from.query.id && to.query.id != null) {
		// 			//id不为空才请求数据
		// 			this.editform = to.query.edit;
		// 			this.editId = to.query.id;
		// 			this.getEditList();
		// 		}
		// 		if (to.query.edit != null && to.query.edit != undefined) {
		// 			this.editform = to.query.edit;
		// 			if (this.editform==true) {
		// 				if (to.query.id != from.query.id && to.query.id != null) {
		// 					//id不为空才请求数据
		// 					this.editId = to.query.id;
		// 					this.getEditList();
		// 				}
		// 			} else {
		// 				this.fCapTypeIDList = [];
		// 				this.fCapTypeOptions = [];
		// 				this.tableData = [];
		// 				this.editId = "";
		// 				this.editform = false;
		// 				this.form = {};
		// 				this.radio = false;
		// 				this.$refs.form.clearValidate();
		// 				this.inintApplicationPriceNum();
		// 				this.getShopList();
		// 				this.getdedelivery();
		// 			}
		// 		}
		// 	},
		// },
		methods: {
			//点击下拉框选中的数据
			cjchange(e){
				// console.log(e,'曹家吗1')
				console.log(this.subscript,'曹家吗2')
				this.tableData.forEach((item,index)=>{
					for(var i=0;i<this.subscript.length;i++){
						if(index == i){
							item.fDeliveryID = this.subscript[i]
						}
					}
				})
				// console.log(this.tableData,'曹家吗3')
			},
			
			setOnlyMoney(numbersmax,index) {
				this.$nextTick(() => {
					let val = numbersmax.toString();
					val = val.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
					val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
					val = val.replace(/^0+\./g, '0.');
					val = val.match(/^0+[1-9]+/) ? val = val.replace(/^0+/g, '') : val
					val = (val.match(/^\d*(\.?\d{0,2})/g)[0]) || ''
					this.tableData[index].fApplyGoodsPrice = val;
				});
			},
			handleClick(a) {
				this.iContent = a;
			},
			changesselect(e) {
				let status = false;
				for (var i = 0; i < e.length; i++) {
					if (i == 1) {
						status = true
					}
				}
				if (status == true) {

				} else {
					console.log(this.selectdefult)
					this.fCapTypeIDList.unshift(this.selectdefult)
				}
			},
			
			changefCapTypeIDList(e){
				console.log(e,'付款方式列表')
				console.log(this.zhuceData)
				// this.zhuceData = [];
				let arr = [];
				let xieyidatas = []
				
				console.log(this.fCapTypeOptions)
				for(var i = 0 ; i< this.fCapTypeOptions.length ; i++){
					for(var j = 0; j< e.length ;j++){
						if(this.fCapTypeOptions[i].fCapTypeID == e[j]){
							arr.push(this.fCapTypeOptions[i])
						}
					}
				}
				
				console.log(arr,'数据列表')
				let stringlist = []
				for(var i =0 ; i<arr.length;i++){
					console.log('循环了几次',i)
					console.log(arr[i].fCapType,'数据列表')
					if(arr[i].fCapType =="货币资金"){
						
						stringlist.push(2)
					}
					if(arr[i].fCapType =="承兑汇票"){
						stringlist.push(1)
					}
					if(arr[i].fCapType =="授信"){
						stringlist.push(0)
					}
				}
				
				if(this.xieyilists.length > 0){
					let  showlist=[];
					for(var i =0;i<stringlist.length;i++){
						showlist.push(this.xieyilists[stringlist[i]])
					}
					this.zhuceData = showlist
					console.log('showlists',showlist)
				}

			},
			
			//获取提货方式
			Deliverymethod(){
				this.ApiRequestPostNOMess("api/mall/goods/goods-class-depot-type-rf/get-all-list",{})
				.then(
					(res) =>{
						// console.log(res)
						this.mode=res.obj
						
					}
				)
			},
			assignment(){
				// this.Valuetransmission()
				// console.log(this.mode)
				for (let i=0;i<this.mode.length;i++){
					for (let j=0;j<this.tableData.length;j++){
						// console.log(123456789,this.mode[i].fGoodsClassID , this.tableData[j].fGoodsClassID)
						if(this.mode[i].fGoodsClassID == this.tableData[j].fGoodsClassID){
							var delivery = []
							    delivery = this.mode[i].fDeliveryList
								this.tableData[j].delivery = delivery
								delivery.map(item=>{
									console.log(item)
									if(item.fDelivery == '自提'){
										// console.log(this.tableData[j])
										this.subscript[j] = item.fDeliveryID
										this.tableData[j].fDeliveryID = item.fDeliveryID
									}
								})
								
							// this.options.label.push(this.mode.fDeliveryList.fDelivery)
						}
					}
				}
				// console.log(741256458425,this.options.label)
			},
			// 获取授信协议
			getCreaditList(){
				if (this.shopID == "") {
					this.ApiRequestPostNOMess("/api/mall/ebbase/agreement-history/get-by-credit-list", {
						fShopID: -1
					}).then(
						(res) => {
							console.log(res, '授信协议');
							for(var i = 0 ; i< res.obj.items.length;i++){
								this.zhuceData.push({
									fAgreementHistID:res.obj.items[i].fAgreementHistID,
									fAgreementTypeID:res.obj.items[i].fAgreementTypeID,
									fTitle:res.obj.items[i].fTitle	
								})
							}
							// this.iContenthtml=res.obj.items[0].fContent
						},
						(error) => {}
					);
				} else {
					this.ApiRequestPostNOMess("/api/mall/ebbase/agreement-history/get-by-credit-list", {
						fShopID: this.shopID
					}).then(
						(res) => {
							console.log(res, '授信协议');
							for(var i = 0 ; i< res.obj.items.length;i++){
								this.zhuceData.push({
									fAgreementHistID:res.obj.items[i].fAgreementHistID,
									fAgreementTypeID:res.obj.items[i].fAgreementTypeID,
									fTitle:res.obj.items[i].fTitle	
								})
							}
							// this.iContenthtml=res.obj.items[0].fContent
						},
						(error) => {}
					);
				}
			},
			
			// 获取票据协议
			getebcnList(){
				if (this.shopID == "") {
					this.ApiRequestPostNOMess("/api/mall/ebbase/agreement-history/get-by-ebcn-list", {
						fShopID: -1
					}).then(
						(res) => {
							console.log(res, '票据协议');
							for(var i = 0 ; i< res.obj.items.length;i++){
								this.zhuceData.push({
									fAgreementHistID:res.obj.items[i].fAgreementHistID,
									fAgreementTypeID:res.obj.items[i].fAgreementTypeID,
									fTitle:res.obj.items[i].fTitle	
								})
							}
							console.log(this.zhuceData)
							// this.iContenthtml=res.obj.items[0].fContent
						},
						(error) => {}
					);
				} else {
					this.ApiRequestPostNOMess("/api/mall/ebbase/agreement-history/get-by-ebcn-list", {
						fShopID: this.shopID
					}).then(
						(res) => {
							console.log(res, '票据协议');
							for(var i = 0 ; i< res.obj.items.length;i++){
								this.zhuceData.push({
									fAgreementHistID:res.obj.items[i].fAgreementHistID,
									fAgreementTypeID:res.obj.items[i].fAgreementTypeID,
									fTitle:res.obj.items[i].fTitle	
								})
							}
							console.log(this.zhuceData)
							// this.iContenthtml=res.obj.items[0].fContent
						},
						(error) => {}
					);
				}
			},
			
			// 企业购协议
			getAgreement() {
				console.log('this.shopID',this.shopID)
				if (this.shopID == "") {
					this.ApiRequestPostNOMess("/api/mall/ebbase/agreement-history/get-by-B2BInquiryApply-list", {
						fShopID: -1
					}).then(
						(res) => {
							for(var i = 0 ; i< res.obj.items.length;i++){
								if(i == 2){
									console.error(res.obj.items[i])
									this.zhuceData.push({
										fAgreementHistID:res.obj.items[i].fAgreementHistID,
										fAgreementTypeID:res.obj.items[i].fAgreementTypeID,
										fTitle:res.obj.items[i].fTitle	
									})
								}
								
								this.xieyilists.push({
									fAgreementHistID:res.obj.items[i].fAgreementHistID,
									fAgreementTypeID:res.obj.items[i].fAgreementTypeID,
									fTitle:res.obj.items[i].fTitle	
								})
							}
							
							console.log(this.zhuceData,'zhucedata')
							// this.iContenthtml=res.obj.items[0].fContent
						},
						(error) => {}
					);
				} else {
					console.log(this.shopID)
					this.ApiRequestPostNOMess("/api/mall/ebbase/agreement-history/get-by-B2BInquiryApply-list", {
						fShopID: this.shopID
					}).then(
						(res) => {
							console.log('企业购协议',res.obj.items)
							this.zhuceData = []
							this.xieyilists = []
							for(var i = 0 ; i< res.obj.items.length;i++){
								if(i == 2){
									this.zhuceData.push({
										fAgreementHistID:res.obj.items[i].fAgreementHistID,
										fAgreementTypeID:res.obj.items[i].fAgreementTypeID,
										fTitle:res.obj.items[i].fTitle	
									})
								}
								this.xieyilists.push({
									fAgreementHistID:res.obj.items[i].fAgreementHistID,
									fAgreementTypeID:res.obj.items[i].fAgreementTypeID,
									fTitle:res.obj.items[i].fTitle	
								})
							}
							// console.log(this.zhuceData,'zhucedataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
							// this.iContenthtml=res.obj.items[0].fContent
						},
						(error) => {}
					);
				}

			},
			//获取初始化议价单号
			inintApplicationPriceNum() {
				this.loading = true;
				this.ApiRequestPostNOMess(
					"api/mall/ebsale/b2b-inquiry-apply/init", {}
				).then(
					(res) => {
						this.form.fBillNumber = res.obj.fBillNumber;
						this.loading = false;
					},
					(error) => {}
				);
			},
			getEditList() {
				this.ApiRequestPost("/api/mall/ebsale/b2b-inquiry-apply/get", {
					id: this.editId,
				}).then(
					(result) => {
						let curObj = result.obj;
						// curObj.fCreateTime = this.getTime(curObj.fCreateTime);
						// curObj.fModifyTime = this.getTime(curObj.fModifyTime);
						curObj.fBeginTime = this.getDate(curObj.fBeginTime);
						curObj.fEndTime = this.getDate(curObj.fEndTime);
						//店铺选中处理后对应的商品和类型相应处理
						console.log('this.listData', this.listData);
						this.shopID = this.listData[0].fShopUnitID
						this.getAgreement()
						for (let item of this.listData) {
							if (curObj.fShopUnitID == item.fShopUnitID) {
								item.checked = true;
								this.form.fShopUnitID = item.fShopUnitID;
								this.getgoodsTabData(item.fShopUnitID, this.editform);
								this.getfCapType(item.fShopUnitID);
							} else {
								item.checked = false;
							}
						}
						this.form.fBillNumber = curObj.fBillNumber;
						this.form.fCapTypeIDS = curObj.fCapTypeIDS;
						this.form.fCapTypeS = curObj.fCapTypeS;
						this.form.fBeginTime = curObj.fBeginTime;
						this.form.fEndTime = curObj.fEndTime;
						this.form.fUsePlace = curObj.fUsePlace;
						this.form.fReason = curObj.fReason;
						this.form.fRemark = curObj.fRemark;
						this.form.fInquiryApplyID = curObj.fInquiryApplyID;
						this.form.fShopUnitID = curObj.fShopUnitID;
						this.form.details = curObj.details;
						this.form.frv = curObj.frv;
						this.form.rowStatus = 16;
						this.fCapTypeIDList = curObj.fCapTypeIDS.split(",");
						// this.fCapTypeOptions = 
						// console.log('获取付款方式表',this.fCapTypeIDList)
						// getfCapType(id,true)
					},
					(error) => {}
				);
			},
			//商品的模糊查询
			// goodsFilterChange() {
			// 	this.getgoodsTabData(this.form.fShopUnitID);
			// },
			//获取店铺列表
			getShopList() {
				this.loading = true;
				this.ApiRequestPost("api/mall/ebshop/baseinfo/get-by-enable-and-have-goods", {
					filter: this.shopFilterText,
					maxResultCount: this.maxResultCount,
					skipCount: (this.page - 1) * this.maxResultCount,
					sorting: "",
				}).then(
					(res) => {
						this.loading = false;
						console.log("获取店铺列表", res.obj.items);
						let shopList = [];
						if (res.obj.items.length != 0) {
							for (let i of res.obj.items) {
								if (i.fShopUnitID != "-1") {
									console.error(i)
									shopList.push(i);
								}
							}
							this.listData = shopList;

							console.log(this.listData, "获取店铺列表完成");
							this.listData.forEach((item, index) => {
								if (item.fShopTypeID == '1') {
									this.shopitemClick(item.fShopUnitID)
								}
								if (item.fShopLogoPath == "") {
									this.listData[index].fShopLogoPath = require("@/assets/shoplogo.png")
								}
							})
							if (this.editform == true) {
								this.getEditList();
							}
						} else {
							this.listData = [];
						}
						console.error(this.listData[0].fShopUnitID)
						this.shopID = this.listData[0].fShopUnitID
						this.getAgreement()
					},
					(error) => {}
				);
			},
			//店铺的模糊查询
			shopFilterChange() {
				this.getShopList();
			},
			//点击店铺事件
			shopitemClick(id) {
				if (id == this.shopID) {
					console.log('两次点击一样数据')
					// 变换checked控制样式
					for (let item of this.listData) {
						if (id == item.fShopUnitID) {
							item.checked = true;
						} else {
							item.checked = false;
						}
					}
				} else {
					this.shopID = id;
					this.form.fShopUnitID = this.shopID;
					this.getfCapType(this.form.fShopUnitID); //获取当前店铺可用的支付方式
					// 当前店铺切换时,商品为空
					this.getAgreement() //当前店铺协议列表
					// 如果是修改,获取店铺之前议价中的商品
					// this.getgoodsTabData(item.fShopUnitID, this.editform);
					// 变换checked控制样式
					for (let item of this.listData) {
						if (id == item.fShopUnitID) {
							item.checked = true;
						} else {
							item.checked = false;
						}
					}
					this.$forceUpdate();

					this.tableData = []
				}
			},
			//议价商品选择弹框
			changeModel(val) {
				// console.log(val);
				this.dialogVisible = val;
			},
			//议价商品选择回传数据
			handleAddDialogChange(val) {
				console.log('商品选择回传数据', val);
				
				// 整合两个数据,如果以前的数据为空，直接把值赋给页面，如果不为空，就整合页面
				let arr = this.tableData;
				if (arr.length == 0) {
					this.tableData = val
				} else {
					//以前的数据
					for (var index = 0; index < val.length; index++) {
						var exitData = arr.filter(item => item.fGoodsID == val[index].fGoodsID)
						if (exitData.length > 0) {
							arr[index].fUnitNumber = parseInt(arr[index].fUnitNumber) + 1;
						} else {
							arr.push(val[index]);
						}
					}

					this.tableData = arr
				}
				this.assignment()
				// console.log(741852777777,this.tableData )
				// if (val.length > 0) {
				// 	this.tableData.forEach(item => {
				// 		item.fDeliveryID = this.deliveryMethodoptions[1].value
				// 	});
				// }
				
			},
			// 删除商品
			deleteGood(id) {
				console.log(id);
				this.tableData.some((item, index) => {
					if (item.fGoodsID == id) {
						this.tableData.splice(index, 1)
					}
				})
			},
			//table点击
			// handleSelectionChange(row) {
			// 	this.tableData = row;
			// this.tableData.forEach((item, index) => {
			//   this.tableData.forEach((item1, index1) => {
			//     if (item.fGoodsID == item1.fGoodsID) {
			//       item.disabled = true;
			//     }
			//   });
			// });
			// },

			//展示协议
			showAgreeDia(a,b) {
				console.log(a)
				this.iContent = a;
				this.$refs.areDialog.show();
			},
			//点击店铺后获取对应店铺的商品信息
			getgoodsTabData(id, isedit) {
				this.tableLoading = true;
				this.ApiRequestPost(
					"api/mall/ebsale/goods-price/get-list-by-shopunitid", {
						fShopUnitID: id,
						fAppTypeID: this.fAppTypeID,
						filter: this.goodsFilterText,
						maxResultCount: this.maxResultCount,
						skipCount: (this.page - 1) * this.maxResultCount,
						sorting: "",
						fSortType: 0,
						fSortMode: 0,
					}
				).then(
					(res) => {
						this.tableLoading = false;
						console.log('编辑后书据=====================',this.form.details)
						
						if (isedit) {
							for(var i =0; i<this.form.details.length;i++){
								this.form.details[i].fGoodsPrice =  this.form.details[i].fGoodsPrice_Old
							}
							this.tableData = this.form.details;
						} else {
							for (var i = 0; i < res.obj.items.length; i++) {
								res.obj.items[i].fDeliveryID = 2;
								// res.obj.items[i].fDelivery = "自提";
								res.obj.items[i].fApplyGoodsPrice = "";
							}
							this.tableData = res.obj.items;
						}
						if (this.form.details.length > 0) {
							this.$nextTick(() => {
								this.form.details.forEach((row) => {
									this.$refs.tableData.toggleRowSelection(
										this.tableData.find((item) => {
											return row.fGoodsID == item
												.fGoodsID; // 注意这里寻找的字段要唯一，示例仅参考
										}),
										true
									);
								});
							});
						}
					},
					(error) => {}
				);
			},
			//点击店铺后获取当前店铺可用的支付方式
			getfCapType(id,isedit) {
				console.log(isedit,'编辑状态')
				this.ApiRequestPost(
					"api/mall/ebsale/b2b-inquiry-apply/get-settlemode-rule", {
						id: id,
					}
				).then(
					(res) => {
						//console.log("获取资金列表", res.obj);
						this.fCapTypeOptions = [];
						this.fCapTypeIDList = [];
						this.fCapTypeOptions = res.obj;
						this.fCapTypeOptions[0].checked = true;
						this.selectdefult = this.fCapTypeOptions[0];
						this.fCapTypeIDList.push(this.fCapTypeOptions[0].fCapTypeID);
						console.log(this.fCapTypeOptions, '拿到交易方式数据----')
						console.log(
						this.fCapTypeOptions,
						"this.fCapTypeOptionsthis.fCapTypeOptions"
						);
					},
					(error) => {}
				);
			},
			//获取提货方式数据
			getdedelivery() {
				this.ApiRequestPost("api/mall/ebbase/delivery-mode/get-onstatus-list", {
					maxResultCount: 10,
					filter: "",
					sorting: "",
					skipCount: 0,
				}).then(
					(result) => {
						console.log(result.obj, "res.objres.objres.obj");
						for (var i = 0; i < result.obj.items.length; i++) {
							this.deliveryMethodoptions.push({
								label: result.obj.items[i].fDelivery,
								value: result.obj.items[i].fDeliveryID,
							});
						}
					},
					(error) => {}
				);
			},
			handlecheckboxChange(val) {
				this.fCapTypeIDList = val;
			},
			// 提货方式
			fDeliveryChange(row) {
				row.fDeliveryID = row.fDeliveryID;
			},
			//确定提交
			submitForm(formName) {
				let falg;
				let numberMax;
				console.log(this.tableData)
				this.tableData.forEach((item, index1) => {
					// if( parseInt(item.fApplyGoodsPrice)> 99999){
					// 	numberMax = false
					// }form.fBeginTime
					if (
						this.tableData[index1].fApplyGoodsPrice == "" ||
						this.tableData[index1].fApplyGoodsPrice == null ||
						this.tableData[index1].fApplyGoodsPrice == undefined
					) {
						return (falg = false);
					}
				});
				// if(!numberMax){
				// 	this.$message("选中的议价商品议价不能超过99999");
				// 	numberMax= true;
				// 	return;
				// } 
				if (falg == false) {
					this.$message("选中的议价商品的价格不能为空");
					return false;
				}
				if (this.tableData.length == 0) {
					this.$message("请选择需要议价的商品");
					return false;
				}
				let start = Date.parse(this.form.fBeginTime);
				let now = null;
				// 取值当天0点
				now =  new Date(new Date(new Date().toLocaleDateString()).getTime()+24*60*60*1000-1)-86400000; //当前时间
				let end = Date.parse(this.form.fEndTime);

				//进行比较

				if (start <= now) {
					this.$message("开始时间不能小于当天");
					return;
				}
				if (start >= end) {
					console.log()
					this.$message("结束时间不能早于或者等于开始时间");
					return;
				}
				if (this.radio == false && this.zhuceData.length>0) {
					this.$message("请勾选企业购协议");
					return false;
				}
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.fCapTypeIDList.length > 0) {
							this.form.fCapTypeIDS = this.fCapTypeIDList.toString();
							let arry1 = [];
							this.fCapTypeIDList.forEach((item1) => {
								this.fCapTypeOptions.forEach((item2) => {
									if (item1 == item2.fCapTypeID) {
										arry1.push(item2.fCapType);
									}
								});
							});
							this.form.fCapTypeS = arry1.toString();
						}
						this.form.details = [];
						console.log('this.tableData', this.tableData);
						for (let a of this.tableData) {
							this.form.details.push({
								fSaleAmount: a.fSalesVolume,
								fApplyGoodsPrice: a.fApplyGoodsPrice,
								fGoodsID: a.fGoodsID,
								fDeliveryID: a.fDeliveryID,
							});
						}
						if (this.editform == true) {
							this.formloading = true;
							this.ApiRequestPut("api/mall/ebsale/b2b-inquiry-apply/update",
								this.form
							).then(
								(result) => {
									this.formloading = false;
									this.fCapTypeIDList = [];
									this.tableData = [];
									this.editId = "";
									this.radio = false;
									this.editform = false;
									this.form = {};
									this.$router.push({
										path: "/businessBuy",
									});
									// this.handleClose();
								},
								(error) => {
									// alert(error);
								}
							);
						} else {
							this.formloading = true;
							this.ApiRequestPost(
								"api/mall/ebsale/b2b-inquiry-apply/create",
								this.form
							).then(
								(result) => {
									this.formloading = false;
									this.fCapTypeIDList = [];
									this.tableData = [];
									this.form = {};
									this.radio = false;
									this.editId = "";
									this.$message({
										type: 'success',
										message: '议价单已提交,请等待审核'
									})
									this.$router.push({
										path: "/businessBuy",
									});
								},
								(error) => {}
							);
						}
					} else {
						return false;
					}
				});
			},
		},
	};
</script>
<style scoped lang="scss">
	.addBargainingGoods {
		width: 150px;
		height: 32px;
		opacity: 1;
		border-radius: 6px;
		color: #0066ff;
		border: 1px solid #0066ff;
		line-height: 32px;
	}

	.deleteBtn {
		width: 75px;
		height: 24px;
		line-height: 24px;
	}

	.priceBuy-bgcimg {
		width: 100%;
		height: 100%;
		background: url(../../assets/bussinessBuy/priceBuy-bgc.jpg) no-repeat;
		background-size: 100% 100%;
	}

	.bgcimg {
		width: 100%;
		position: fiexd;
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.borederColor {
		@include themify($themes) {
			border: 2px solid themed("themes_color");
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.popup_content:hover {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.backgroundcolor-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}
	.el-picker-panel__footer ::v-deep .el-button--text{
		display: none !important;
	}
	
	.borederNone {
		border: none;
	}

	.breadcrumbDiv {
		width: 1100px;
		height: 30px;
		margin: 0 auto;
		padding-top: 20px;
	}

	.popup_content_item,
	.item_right {
		width: 209px;
	}

	.content {
		width: 1100px;
		min-height: 550px;
		margin: 0 auto;
		// background-color: #ffffff;
	}

	.h50 {
		height: 50px;
	}

	.padding-10 {
		padding: 10px;
	}

	.padding-lr-10 {
		padding-left: 10px;
		padding-right: 10px;
	}

	.margin-b--5 {
		margin-bottom: -5px;
	}

	.margin-auto {
		margin: auto;
	}

	.el-dialog {
		width: 540px;
		height: 525px;
	}

	.popup_content {
		width: 212px;
		height: 85px;
		background: #ffffff;
		border: 1px solid #f2f2f2;
		float: left;
		// margin-left: 8px;
		// margin-top: 8px;
		display: flex;
	}

	.MyFocus-content {
		background-color: #f8f9fe;
		width: 100%;
		max-height: 263px;
		min-height: 67px;
		padding-bottom: 13px;
		padding-top: 5px;
		scrollbar-width: 5px;
		/* firefox */
		-ms-overflow-style: 5px;
		/* IE 10+ */
		overflow-x: hidden;
		overflow-y: scroll;
	}

	.svg-icon {
		height: 230px;
	}

	/* width */
	::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: rgb(255, 255, 255);
		border-radius: 8px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #dddddd;
		border-radius: 8px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: rgb(162, 162, 163);
	}

	.MyFocus-img img {
		display: block;
		width: 55px;
		height: 55px;
		border-radius: 5px;
		border: 1px solid #e4e4e4;
	}

	.shop_pic {
		margin: 10px;
	}

	.shop_name {
		color: #000000;
		width: 100%;
	}

	.margin-tb-6-16 {
		margin: 6px 0 13px 0;
	}

	.margin-t3-r9 {
		margin: 0px 3px 9px 0;
	}

	.padding-t-21 {
		padding-top: 21px;
	}

	.u_mc_smallp {
		display: block;
		font-size: 12px;
		color: #666666;
		margin: 6px 0px 0px 45px;
	}

	.concer_leftbox {
		width: 75px;
		height: 24px;
		background: rgba(255, 255, 255, 0);
		border: 1px solid #0173fe;
		border-radius: 30px;
		color: #0173fe;
		text-align: center;
		margin: 0px 8px 16px 212px;
	}

	.iconfont {
		position: absolute;
		top: 8px;
		right: 8px;
		color: #cccccc;
		font-size: 18px;
	}

	::v-deep .el-input__icon {
		line-height: 32px;
	}

	::v-deep .el-table__header-wrapper {
		height: 35px;
	}

	::v-deep .el-table__row {
		// height: 49px;
	}

	.xinzengIcon {
		font-size: 26px;
		position: relative;
		left: 4px;
		top: 3px;
	}

	.form-content {
		position: relative;
		z-index: 8;
		width: 90%;
		margin: 0 auto;
	}

	.bg-box {
		width: 100%;
		z-index: 5;
		height: 230px;
		background: url(../../assets/imgs/u305.png) no-repeat;
		background-size: 100% 310px !important;
		// background-position: right bottom;
		margin-top: -154px !important;
		// opacity: 25%;
	}

	.deliveryBox {
		line-height: 30px;
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	::v-deep .el-input-number__increase {
		right: 0;
	}

	::v-deep .footer .el-form-item__content {
		margin-left: 0 !important;
		text-align: center !important;
	}

	::v-deep .footer div {
		width: 155px;
		margin: -26px auto 0;
	}

	::v-deep th {
		background-color: #f2f4fd;
	}

	::v-deep .el-form-item {
		margin-top: 20px;
		margin-bottom: 0;
	}

	::v-deep .el-date-editor.el-input {
		width: 100%;
	}

	::v-deep .deliveryBox .el-input__inner {
		line-height: 30px !important;
		font-size: 13px;
	}

	::v-deep .el-table td {
		padding: 7px 0;
		color: #333333;
		font-size: 13px;
	}

	::v-deep .el-table th {
		padding: 8px 0;
	}

	// ::v-deep .el-table__header {
	// 	width: 1106px !important;
	// }

	// ::v-deep .el-table__body {
	// 	width: 1089px !important;
	// }

	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
	::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
		@include themify($themes) {
			background-color: themed("themes_color");
			border-color: themed("themes_color");
		}
	}

	::v-deep .el-input-number {
		line-height: 29px;
		width: 135px;
	}

	.tag-box {
		float: left;
		border-radius: 4px;
		margin-top: 6px;
		max-width: 70px;
		height: 17px;
		background-image: linear-gradient(to right, #ff0000, #ff9999);
	}

	.line-height46 {
		line-height: 46px;
	}

	.line-height27 {
		line-height: 27px;
	}

	.margin-top-3px {
		margin-top: -3px;
	}

	::v-deep .el-table__body-wrapper::-webkit-scrollbar {
		width: 8px; // 横向滚动条
		height: 8px; // 纵向滚动条 必写
	}

	// 滚动条的滑块
	::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
		background-color: #dddddd;
		border-radius: 8px;
	}

	::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
		background: rgb(162, 162, 163);
	}

	::v-deep .el-breadcrumb__inner.is-link {
		font-weight: 400;
		color: #999999;
	}

	::v-deep .el-breadcrumb__inner {
		color: #999999;
	}

	.color-red {
		color: #ff0000;
	}

	.width-100 {
		width: 105px;
		margin-left: 5px;
	}

	.lh21 {
		line-height: 21px;
	}

	.application-form {
		height: 348px;
		box-sizing: border-box;
		margin-bottom: 41px;
	}

	.agreement-box {
		width: 18%;
		margin: 10px auto;
		color: #999999;
	}

	.agreement-box {
		width: 100%;
		margin: 12px auto;
		display: flex;
		justify-content: center;
	}

	.agreement-box ::v-deep .el-checkbox__label {
		color: #999999;
		font-size: 12px;
	}

	.margin-t3-r9 ::v-deep .el-input__inner {
		font-size: 12px !important;
		color: rgb(153, 153, 153) !important;
	}

	::v-deep .el-input {
		font-size: 12px;
	}

	::v-deep .el-input-number__decrease,
	::v-deep .el-input-number__increase {
		width: 30px;
	}

	::v-deep .el-select-dropdown__item {
		font-size: 12px !important;
	}

	::v-deep .el-tag__close {
		display: none;
	}
	::v-deep .dataspicker >>> .el-picker-panel__link-btn {
	    display: none !important;
	}
	
</style>
